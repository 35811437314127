import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Du willst also deine Website barrierefrei machen? Sehr gut! Und du hast bereits von
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA"
      }}>{`ARIA`}</a>{` gehört,
also `}<span lang="en">{`Accessible Rich Internet Applications`}</span>{`. Na, dann pflastern wir doch einfach alles
mit ARIA-Attributen zu und die Website ist barrierefrei, oder? Falsch!`}</p>
    <p>{`Wenn du nicht weißt, was du tust, wird deine Website am Ende weniger barrierefrei sein! Es gibt einen guten Grund,
warum die erste Regel von ARIA lautet: `}<strong lang="en">{`No ARIA is better than Bad ARIA!`}</strong>{` Ihr solltet
grundsätzlich native HTML-Elemente und -Attribute mit der gewünschten Semantik und Funktionalität verwenden.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "63.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAFjtM2jmkP/xAAbEAACAwADAAAAAAAAAAAAAAABEgACExEhIv/aAAgBAQABBQLVpfhRiYO6q8t5P//EABURAQEAAAAAAAAAAAAAAAAAAAAh/9oACAEDAQE/AUf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAdEAACAQQDAAAAAAAAAAAAAAAAATECEBGBEkFx/9oACAEBAAY/AtC47IwNx4ijq3//xAAZEAEBAQEBAQAAAAAAAAAAAAABETEAIVH/2gAIAQEAAT8hsZcdokRdb0NafK8W4IUw6I4dnVAfO//aAAwDAQACAAMAAAAQ4B//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFR/9oACAEDAQE/EHGtJg//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAECAQE/EAxu3//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExQYFxocH/2gAIAQEAAT8QM2MgG7W4bYjBoAc+p3fAfZrroASh13Lt9iqN5y+cQ2fyB4n/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Eine Frau sitzt am Boden und balanciert einen Laptop auf ihren Knien. Sie blickt verdutzt zum Mann neben ihr.",
          "title": "Eine Frau sitzt am Boden und balanciert einen Laptop auf ihren Knien. Sie blickt verdutzt zum Mann neben ihr.",
          "src": "/static/d4e56c8e213c409c18497393ef2b4a01/e5166/pexels-ketut-subiyanto-confused-couple.jpg",
          "srcSet": ["/static/d4e56c8e213c409c18497393ef2b4a01/f93b5/pexels-ketut-subiyanto-confused-couple.jpg 300w", "/static/d4e56c8e213c409c18497393ef2b4a01/b4294/pexels-ketut-subiyanto-confused-couple.jpg 600w", "/static/d4e56c8e213c409c18497393ef2b4a01/e5166/pexels-ketut-subiyanto-confused-couple.jpg 1200w", "/static/d4e56c8e213c409c18497393ef2b4a01/b17f8/pexels-ketut-subiyanto-confused-couple.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Ketut Subiyanto / pexels.com`}</em></p>
    <p>{`Sehen wir uns gemeinsam an, welche Anwendungsfälle es für ARIA gibt und welche verbreiteten Fehler ihr vermeiden solltet.`}</p>
    <h2>{`ARIA, wozu ist das gut?`}</h2>
    <p>{`ARIA besteht aus einer Reihe von `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles"
      }}>{`Rollen`}</a>{` und
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes"
      }}>{`Attributen`}</a>{`, die euch dabei helfen können,
Web-Inhalte barrierefrei für Menschen mit Beeinträchtigung aufzubereiten. Die aktuelle Version
`}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria-1.2/"
      }}>{`WAI-ARIA 1.2`}</a>{` wurde im Dezember 2021 vom W3C veröffentlicht.`}</p>
    <p>{`Personen mit Beeinträchtigungen oder Behinderungen nutzen assistive Technologien, um mit Web-Inhalten zu interagieren.
Zum Beispiel surfen blinde Menschen mithilfe von Screenreadern im Internet. Personen mit motorischer Beeinträchtigung können
Spracheingabe-Software verwenden, um mit einer Website zu interagieren.`}</p>
    <p>{`Web-Entwickler:innen können ARIA-Rollen und -Attribute verwenden, um Nutzer:innen assistiver Technologien die Semantik
der Inhalte zu vermitteln. Ihr könnt `}<InlineCode mdxType="InlineCode">{`aria-expanded`}</InlineCode>{` auf ein Element setzen, um anzuzeigen, ob
der verknüpfte Bereich ein- oder ausgeklappt ist. Das Attribut `}<InlineCode mdxType="InlineCode">{`aria-selected`}</InlineCode>{` vermittelt, ob zum
Beispiel ein Tab aktuell ausgewählt ist oder nicht.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<h3>
    <button type="button" aria-expanded="false" aria-controls="section1" id="header1">
        Personal Information
    </button>
</h3>
<div id="section1" role="region" aria-labelledby="header1">
    <!-- Panel content that is shown/hidden via button -->
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Grundsätzlich solltet ihr ARIA nur für Custom Widgets wie etwa einen ausklappbaren Bereich verwenden. Seht euch den
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/"
      }}>{`ARIA Authoring Practices Guide`}</a>{` an. Dieser beschreibt detailliert, wie ihr
semantische Informationen bei üblichen Design-Mustern und Widgets vermitteln solltet.`}</p>
    <h2>{`Häufige Fehler bei der Nutzung von ARIA`}</h2>
    <p>{`Ich habe bereits hunderte Websites auf Barrierefreiheit überprüft. Dabei ist mir aufgefallen: Viele Web-Entwickler:innen
haben offensichtlich keine Ahnung was sie tun! Sie verstopfen das DOM mit ARIA-Rollen und -Attributen als ob es kein Morgen
gäbe. Ich zeige euch einige der häufigsten Fehler, auf die ich gestoßen bin.`}</p>
    <h3>{`Mit `}<InlineCode mdxType="InlineCode">{`aria-hidden="true"`}</InlineCode>{` ein fokussierbares Element verbergen`}</h3>
    <p>{`ARIA gibt euch die volle Kontrolle über den `}<a parentName="p" {...{
        "href": "https://web.dev/the-accessibility-tree/"
      }}><span lang="en">{`Accessibility Tree`}</span></a>{`.
Dieser stellt eine angepasste Version des DOM für assistive Technologien dar. Das Attribut `}<InlineCode mdxType="InlineCode">{`aria-hidden`}</InlineCode>{`
ermöglicht euch, ein Element aus dem `}<span lang="en">{`Accessibility Tree`}</span>{` zu entfernen und es somit vor
Screenreader-Nutzer:innen zu verbergen.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<a href="https://www.stuff.com" aria-hidden="true">Interesting Stuff</a>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Das Problem dabei: Wenn ihr ein fokussierbares Element, wie etwa einen Link, aus dem `}<span lang="en">{`Accessibility Tree`}</span>{`
entfernt, dann verbleibt das Element dennoch in der Tab-Reihenfolge. Wenn ein:e Screenreader-Nutzer:in mit der Tab-Taste
navigiert und damit den Link fokussiert, wird der Screenreader „leer“ oder etwas ähnliches vorlesen. Bitte tut ihnen das nicht an!`}</p>
    <h3>{`Ein `}<InlineCode mdxType="InlineCode">{`aria-label`}</InlineCode>{` definieren, das nicht dem sichtbaren Label entspricht`}</h3>
    <p>{`Stellt euch vor, ihr seid ein Uni-Student in Wien und wollt euch online für eine Vorlesung anmelden. Wegen eines
`}<a parentName="p" {...{
        "href": "https://de.wikipedia.org/wiki/Tremor"
      }}>{`Handtremors`}</a>{` wollt ihr die Website mit eurer Spracheingabe-Software bedienen.
Ihr findet folgendes Web-Formular vor:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<form action="">
    <div class="form-field">
        <label for="firstname">Vorname</label>
        <input type="text" aria-label="First Name" name="firstname" id="firstname">
    </div>
    <div class="form-field">
        <label for="lastname">Nachname</label>
        <input type="text" aria-label="Last Name" name="lastname" id="lastname">
    </div>
    <!-- Other fields -->
</form>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Um das Formular auszufüllen, wollt ihr das erste Eingabefeld mit der sichtbaren Beschriftung „Vorname“ auswählen. Also
sagt ihr den Sprachbefehl „Vorname klicken“, doch nichts passiert. Was ist hier los?`}</p>
    <p>{`Wie der Code oben zeigt, ist das `}<InlineCode mdxType="InlineCode">{`label`}</InlineCode>{` Element programmatisch mit dem Eingabefeld über
das `}<InlineCode mdxType="InlineCode">{`for`}</InlineCode>{` Attribut verknüpft. Allerdings überschreibt das `}<InlineCode mdxType="InlineCode">{`aria-label`}</InlineCode>{` Attribut
den programmatischen Namen des `}<InlineCode mdxType="InlineCode">{`input`}</InlineCode>{` Elements mit `}<span lang="de">{`„First Name“`}</span>{`. Das heißt,
der Name des Bedienelements enthält nicht die sichtbare Beschriftung.`}</p>
    <p>{`Das Formular ist somit für Nutzer:innen von Spracheingabe-Software schwer bedienbar und es liegt ein klarer
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/label-in-name.html"
      }}>{`WCAG-Verstoß`}</a>{` vor. Übrigens: Es handelt sich um ein
echtes Beispiel eines Web-Formulars auf der Website einer großen Universität in Wien.`}</p>
    <h3>{`Das `}<InlineCode mdxType="InlineCode">{`aria-labelledby`}</InlineCode>{` Attribut verweist auf eine ID, die nicht existiert`}</h3>
    <p>{`Mit dem `}<InlineCode mdxType="InlineCode">{`aria-labelledby`}</InlineCode>{` Attribut könnt ihr ein HTML-Element programmatisch beschriften, indem ihr auf
ein anderes Element verweist. Der Wert sollte eine Liste von einer oder mehreren IDs sein, die auf das Element verweisen,
mit dem ihr das aktuelle Element beschriften möchtet. Ein Beispiel.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<nav aria-labelledby="sidenav-title">
    <h2 id="sidenav-title">Related Content</h2>
    <ul>
        <!-- List of links -->
    </ul>
</nav>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Falls kein Element mit der ID „sidenav-title“ im DOM vorhanden wäre, würde das `}<InlineCode mdxType="InlineCode">{`nav`}</InlineCode>{` Element keine
programmatische Beschriftung erhalten. Die Folge wäre eine suboptimale Erfahrung für Screenreader-Nutzer:innen. Deshalb
prüft bitte genau, ob das mit `}<InlineCode mdxType="InlineCode">{`aria-labelledby`}</InlineCode>{` referenzierte Element tatsächlich vorhanden ist.`}</p>
    <h3>{`Navigations-Menü mit `}<InlineCode mdxType="InlineCode">{`role="menubar"`}</InlineCode>{` oder `}<InlineCode mdxType="InlineCode">{`role="menu"`}</InlineCode></h3>
    <p>{`Viele Websites enthalten eine Seitennavigation, die wie eine Menüleiste mit ausklappbaren Untermenüs gestylt ist. Aufgrund
dieser visuellen Darstellung denken viele Web-Entwickler:innen, dass sie die folgenden semantischen Rollen setzen sollten:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<ul role="menubar">
    <li role="none">
        <a role="menuitem" href="/">Home</a>
    </li>
    <li role="none">
        <a role="menuitem" aria-haspopup="true" aria-expanded="false" href="#">About</a>
        <ul role="menu" aria-label="About">
            <li role="none">
                <a role="menuitem" href="/overview">Overview</a>
            </li>
            <li role="none">
                <a role="menuitem" href="/administration">Administration</a>
            </li>
        </ul>
    </li>
</ul>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Allerdings wecken diese semantischen Rollen eine gewisse Erwartungshaltung bei den Nutzer:innen. Die
Rollen `}<InlineCode mdxType="InlineCode">{`menubar`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`menu`}</InlineCode>{` sind für Menü-Widgets gedacht,
die den Nutzer:innen eine Liste an Aktionen anbieten und wie Menüs von Betriebssystemen bedienbar sind.
Das heißt: Sie sollten ein
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/menu/"
      }}>{`konkretes Set an Tastatur-Interaktionen implementieren`}</a>{`.`}</p>
    <p>{`Wenn ihr zum Beispiel eine Seitennavigation mit `}<InlineCode mdxType="InlineCode">{`role="menubar"`}</InlineCode>{` definiert, dann erwarten
Screenreader-Nutzer:innen, das Menü mit den Pfeiltasten bedienen zu können. Wenn das nicht funktioniert, dann sorgt
das für Verwirrung. Eine bessere Alternative ist die Anwendung des
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/example-index/disclosure/disclosure-navigation.html"
      }}><span lang="en">{`Disclosure Pattern`}</span></a>{`
für die Seitennavigation.`}</p>
    <h2>{`Grundregeln für den Einsatz von ARIA`}</h2>
    <p>{`Wie wir gesehen haben, kann bei ARIA sehr viel schiefgehen. Diese Liste an Grundregeln wird euer Leben einfacher machen:`}</p>
    <ol>
      <li parentName="ol">{`Verwendet immer native HTML-Elemente, wenn möglich.`}</li>
      <li parentName="ol">{`Verändert nicht die Semantik von nativen Elementen, außer es ist wirklich notwendig.`}</li>
      <li parentName="ol">{`Setzt ARIA bei Custom Widgets ein und befolgt die Vorgaben im Authoring Practices Guide.`}</li>
      <li parentName="ol">{`Alle interaktiven ARIA-Bedienelemente müssen auch mit der Tastatur bedienbar sein.`}</li>
      <li parentName="ol">{`Testet euren Code mit assistiven Technologien wie Screenreadern und Spracheingabe-Software.`}</li>
    </ol>
    <h2>{`Nützliche Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA"
        }}>{`ARIA (MDN)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/ARIA/apg/"
        }}>{`ARIA Authoring Practices Guide`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://web.dev/the-accessibility-tree/"
        }}>{`The Accessibility Tree`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      